import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ICertificateDownload } from '../../../../models/certificate-download.model';
import { ICertificate } from '../../../../models/certificates.model';
import { ILocale } from '../../../../models/locale/locale.model';
import { IOfficerAttachment } from '../../../../models/officer-attachment.model';
import { ApplicationsService } from '../../../../services/applications.service';
import { DocumentsService } from '../../../../services/documents.service';
import { LocaleService } from '../../../../services/locale.service';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
enum documentMode {
  preview = 'PREVIEW',
  download = 'DOWNLOAD',
}
@Component({
  selector: 'irembogov-personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss'],
})
export class PersonalDocumentsComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  isLoadingCertificates = false;
  certificatesList: Record<string, ICertificate[]> = {};
  selectedCertificate: ICertificate | undefined;
  selectedAttachment: IOfficerAttachment | undefined;
  filePath: string | undefined;
  isPreviewingCertificate = false;
  isDownloadingCertificate = false;
  downloadedCertificate: ICertificateDownload | undefined;
  downloadedAttachment: ICertificateDownload | undefined;
  showMobileApplicationList = false;
  base64Data: string | undefined;
  supportedLocales: ILocale[] = [];
  selectedCertificateNames: any[] = [];
  certificateNames: Record<string, Record<string, string>[]> = {};
  searchDocTerm = '';
  isLoadingDocuments = false;
  documentsList = [];
  categories: string[] = [];
  selectedCategory = 'View all';
  filteredCertificates: ICertificate[] = [];
  filteredAttachments: IOfficerAttachment[] = [];
  documentMode = documentMode;
  selectedLanguage: string | null = null;
  languageMode: documentMode = documentMode.download;
  officerAttachmentsList: Record<string, IOfficerAttachment[]> = {};
  allowedAttachmentPreviewTypes = ['pdf', 'png', 'jpg', 'jpeg'];
  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private localeService: LocaleService,
    private documentsService: DocumentsService,
    private toastService: ToastService,
    private applicationsService: ApplicationsService
  ) {}

  ngOnInit(): void {
    this.getSupportedLocales();
    this.getActiveCertificates();
    this.getExpiredCertificates();
    this.getOfficersAttachments();
  }

  getSupportedLocales() {
    this.localeService.getSupportedLocales().subscribe({
      next: response => {
        this.supportedLocales = response.data.content;
      },
      error: error => {
        this.toastService.show({
          body: error.error.responseMessage ?? error.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }

  getOfficersAttachments() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getOfficersAttachments().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.officerAttachmentsList = res.data;
          this.categories = [
            ...new Set([
              ...this.categories,
              ...Object.keys(this.officerAttachmentsList),
            ]),
          ];
          this.filterDocuments();
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading officers attachments',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  getActiveCertificates() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getActiveCertificates().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.certificatesList = res.data;
          this.generateActiveCertificateNamesAndLocales();
          this.categories = [
            ...new Set([
              'View all',
              ...this.categories,
              ...Object.keys(this.certificatesList),
            ]),
          ];

          this.filterDocuments();
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading active certificates',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  getExpiredCertificates() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getExpiredCertificates().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.certificatesList['expired'] = res.data;
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading expired certificates',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  filterDocuments() {
    if (this.selectedCategory === 'View all') {
      this.filteredCertificates = Object.values(this.certificatesList).flat();
      this.filteredAttachments = Object.values(
        this.officerAttachmentsList
      ).flat();
    } else {
      this.filteredCertificates =
        this.certificatesList[this.selectedCategory] || [];
      this.filteredAttachments =
        this.officerAttachmentsList[this.selectedCategory] || [];
    }
  }

  //TODO: Search doc
  onEnterInSearchDocInput() {
    console.log('search');
  }
  previewCertificate(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    locale: string
  ) {
    this.selectedCertificate = certificate;
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedCertificate = res;
            const reader = new FileReader();
            reader.readAsDataURL(
              this.base64ToBlob(res?.file, res?.contentType)
            );
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing certificate',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  previewAttachment(
    content: TemplateRef<unknown>,
    attachment: IOfficerAttachment
  ) {
    this.selectedAttachment = attachment;
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(attachment.applicationId, attachment.fileName)
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };

            const reader = new FileReader();
            reader.readAsDataURL(res.body as Blob);
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing attachment',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  downloadAttachment(attachment: IOfficerAttachment) {
    this.selectedAttachment = attachment;
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(attachment.applicationId, attachment.fileName)
        .subscribe({
          next: res => {
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };
            this.downloadBlob(
              attachment?.serviceName +
                ' - ' +
                attachment.applicationNumber +
                attachment.fileName.split('.').pop(),
              res.body as Blob
            );
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading attachment',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
          },
        })
    );
  }

  base64ToBlob(str: string, type: string) {
    // decode base64
    const content = atob(str);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(content.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < content.length; n++) {
      view[n] = content.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type: type });

    return blob;
  }

  downloadCertificate(certificate: ICertificate, locale: string) {
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.downloadedCertificate = res;
            this.downloadBlob(
              certificate?.serviceName +
                ' - ' +
                certificate.applicationNumber +
                '.pdf',
              this.base64ToBlob(res?.file, res?.contentType)
            );
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading certificate',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
        })
    );
  }

  downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
    this.isDownloadingCertificate = false;
  }

  openLanguageSelectorModal(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    mode: documentMode,
    previewContent?: TemplateRef<unknown | undefined>
  ) {
    this.selectedCertificate = certificate;
    this.languageMode = mode;
    if (mode === this.documentMode.download) {
      this.modalService.open(content);
    }
    if (mode === this.documentMode.preview) {
      this.modalService
        .open(content)
        .result.then((result: string) => {
          if (result) {
            this.selectedLanguage = null;
            this.modalService.dismissAll();
            if (previewContent) {
              this.previewCertificate(previewContent, certificate, result);
            }
          }
        })
        .catch(() => {
          // Modal was dismissed
          this.selectedLanguage = null;
        });
    }
  }

  generateCertificateNamesAndLocales(certificate: ICertificate) {
    const selectedCertificateNames: Record<string, string>[] = [];
    Object.entries(certificate.certificateNames).forEach(element => {
      selectedCertificateNames.push({
        locale: element[0],
        localeFlag: this.getLocaleIcon(element[0]),
        localeName: this.getLocaleNameByCode(element[0]),
        certificateName: element[1],
      });
    });

    return selectedCertificateNames;
  }

  generateActiveCertificateNamesAndLocales() {
    Object.entries(this.certificatesList).forEach(entry => {
      entry[1].forEach(certificate => {
        this.certificateNames[certificate.applicationNumber] =
          this.generateCertificateNamesAndLocales(certificate);
      });
    });
  }

  getLocaleIcon(locale: string): string {
    return getUnicodeFlagIcon(
      locale === 'en'
        ? 'GB'
        : locale.includes('-')
        ? locale.split('-')[1]
        : locale
    );
  }

  getLocaleNameByCode(code: string) {
    this.supportedLocales.find(element => element.locale === code)?.name ??
      code;

    if (
      this.supportedLocales.filter(element => element.locale === code).length >
      0
    ) {
      return this.supportedLocales.filter(element => element.locale === code)[0]
        .name;
    }
    return code;
  }

  setSelectedLanguage(locale: string) {
    this.selectedLanguage = locale;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
