<div class="profile d-flex flex-column align-items-start align-self-stretch">
  <div
    class="d-flex flex-column align-items-start gap-4 col-12"
    *ngIf="
      userProfile && doneLoadingProfile && !isLoadingDocuments;
      else loadingState
    ">
    <!-- TODO: Create a component for this banner -->
    <div
      *ngIf="!userProfile.accountVerified && showVerifyBanner"
      class="not-verified d-flex flex-row align-items-start w-100">
      <i class="icon-alert-triangle"></i>
      <div
        class="d-flex flex-column justify-content-between align-items-start align-content-start py-0">
        <span id="title">{{ 'Complete your verification' | translate }}</span>
        <span>{{
          'Verify your account to access all IremboGov services.' | translate
        }}</span>
        <button
          class="btn w-100 p-0 text-start"
          (click)="openVerifyModal(userProfile.userId)">
          {{ 'Verify now' | translate }} <i class="ms-2 icon-chevron-right"></i>
        </button>
      </div>
      <i
        class="icon-x-close ms-auto"
        (click)="showVerifyBanner = !showVerifyBanner"></i>
    </div>
    <!-- User Details -->
    <!-- TODO: Create a component for this section -->
    <div
      class="d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0 gap-md-0 col-12">
      <div
        class="user-details d-flex flex-row justify-content-start align-items-center gap-3">
        <div class="initials">
          <span
            class="user-initials flex-column"
            *ngIf="!userProfile.accountVerified"
            ><i class="icon-user-01"></i
          ></span>
          <span class="user-initials" *ngIf="userProfile.accountVerified">{{
            userInitialsAndName.initials
          }}</span>
        </div>
        <div
          class="d-flex flex-column justify-content-between gap-2 user-details-text">
          <span class="usernames">{{ userInitialsAndName.names }}</span>
          <span
            class="user-nid"
            *ngIf="doneLoadingProfile && userProfile.accountVerified">
            {{ userProfile.identificationNumber }}
          </span>
        </div>
      </div>
      <!-- Action Buttons -->
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3 action-buttons">
        <irembogov-add-business></irembogov-add-business>
        <button
          class="btn w-100 flex-fill btn-password"
          (click)="openChangePassword(updatePassword)">
          <i class="icon-lock-03"></i>{{ 'Change password' | translate }}
        </button>
        <a
          class="btn w-100 text-white flex-fill btn-profile"
          [routerLink]="['/profile', 'settings']">
          <i class="icon-settings-01"></i>{{ 'Profile settings' | translate }}
        </a>
      </div>
    </div>
    <div class="divider"></div>

    <!-- Navigation Tabs -->
    <irembogov-inline-toggle
      class="w-100"
      [activeTab]="selectedApplicationTypeTab"
      (tabChanged)="
        selectedApplicationTypeTab = $event
      "></irembogov-inline-toggle>
    <!-- Tab Content Personal -->
    <div
      class="col-12"
      [ngClass]="{ 'row g-0': !showMobileApplicationList }"
      *ngIf="selectedApplicationTypeTab === 'personal'">
      <ul class="nav flex-column w-100 p-0 gap-4 g-0 d-lg-none d-md-none">
        <li class="nav-item">
          <a
            class="nav-type"
            [routerLink]="['/profile', 'applications', 'personal']">
            Applications
            <i class="icon-chevron-right float-right"></i>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-type"
            [routerLink]="['/profile', 'documents', 'personal']">
            Documents
            <i class="icon-chevron-right float-right"></i>
          </a>
        </li>
      </ul>
      <div
        class="show-items col-md-2 col-lg-2 gap-1"
        [ngClass]="{
          'd-none': totalApplications === 0 || showMobileApplicationList
        }"
        id="nav-tab"
        role="tablist">
        <button
          class="show-item btn btn-outline-none"
          [ngClass]="{ active: selectedTab === 'apps' }"
          data-bs-toggle="tab"
          data-bs-target="#nav-apps"
          type="button"
          role="tab"
          (click)="selectedTab = 'apps'"
          [attr.aria-selected]="selectedTab === 'apps'">
          {{ 'My Applications' | translate }}
        </button>
        <button
          class="show-item btn btn-outline-none"
          [ngClass]="{ active: selectedTab === 'docs' }"
          data-bs-toggle="tab"
          data-bs-target="#nav-docs"
          type="button"
          role="tab"
          (click)="selectedTab = 'docs'"
          [attr.aria-selected]="selectedTab === 'docs'">
          {{ 'My Documents' | translate }}
        </button>
      </div>
      <div
        *ngIf="!showMobileApplicationList"
        class="results-view tab-content"
        [ngClass]="
          totalApplications === 0
            ? 'col-md-12 col-lg-12'
            : 'col-md-10 col-lg-10'
        "
        id="nav-tabContent">
        <!-- Applications -->
        <ng-container *ngIf="selectedTab === 'apps'">
          <irembogov-application-list
            *ngIf="totalApplications !== 0; else noApplications"
            [showMobileApplicationList]="showMobileApplicationList"
            [applicationsList]="applicationsList"
            [totalApplications]="totalApplications"
            [isLoadingApplications]="isLoadingApplications"
            (getApplications)="
              getApplications(
                $event.pageNumber,
                $event.pageSize,
                $event.sort,
                $event.searchTerm
              )
            "
            (viewApplicationDetails)="
              openApplicationDetails(applicationData, $event)
            "></irembogov-application-list>
        </ng-container>
        <!-- End applications  -->

        <!-- Documents -->
        <ng-container *ngIf="selectedTab === 'docs'">
          <irembogov-documents-list
            *ngIf="filteredCertificates.length !== 0; else noDocuments"
            [filteredAttachments]="filteredAttachments"
            [filteredCertificates]="filteredCertificates"
            [categories]="categories"
            [selectedCategory]="selectedCategory"
            [showMobileApplicationList]="showMobileApplicationList"
            [isLoadingDocuments]="isLoadingDocuments"
            [documentMode]="documentMode"
            [languageSelector]="languageSelector"
            [certificate_content]="certificate_content"
            [attachment_content]="attachment_content"
            [certificateNames]="certificateNames"
            [allowedAttachmentPreviewTypes]="allowedAttachmentPreviewTypes"
            (DocumentsListFiltered)="filterDocuments()"
            (LanguageSelectorModalOpened)="
              openLanguageSelectorModal(
                languageSelector,
                $event.certificate,
                $event.documentMode,
                certificate_content
              )
            "
            (PreviewAttachment)="previewAttachment(attachment_content, $event)"
            (DownloadAttachment)="
              downloadAttachment($event)
            "></irembogov-documents-list>
        </ng-container>
      </div>
    </div>
    <!-- Tab Content Business -->
    <div
      class="col-12"
      [ngClass]="{ 'row g-0': !showMobileApplicationList }"
      *ngIf="selectedApplicationTypeTab === 'business'">
      <irembogov-business-list></irembogov-business-list>
    </div>
  </div>
</div>

<ng-template #loadingState>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100 vh-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
<!-- No Applications Template -->
<ng-template #noApplications>
  <div
    class="no-applications d-flex flex-column justify-content-center align-items-center col-12 h-100 w-100">
    <div class="icons-container">
      <i class="icon-layers-three-01"></i>
    </div>
    <div class="title">{{ 'Your application list is empty!' | translate }}</div>
    <div class="description col-10 col-lg-4 col-md-4">
      {{
        "You haven't submitted any applications yet. Browse available services and get started!"
          | translate
      }}
    </div>
  </div>
</ng-template>
<!-- No Documents Template -->
<ng-template #noDocuments>
  <div
    class="no-applications d-flex flex-column justify-content-center align-items-center w-100">
    <div class="icons-container">
      <i class="icon-file-06"></i>
    </div>
    <div class="title">{{ 'Your documents list is empty!' | translate }}</div>
    <div class="description col-10 col-lg-4 col-md-4">
      {{
        'Once you submit an application and it progresses, any documents issued by the service provider will be displayed here.'
          | translate
      }}
    </div>
  </div>
</ng-template>
<!-- Certficate preview -->
<ng-template #certificate_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="tt_norms500 preview-header">
        <i class="fa fa-file-text"></i> {{ selectedCertificate?.serviceName }} -
        {{ selectedCertificate?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedCertificate?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #imagePreview>
  <img
    *ngIf="filePath"
    [src]="filePath"
    alt="Image preview"
    class="w-100 h-100 mt-3" />
</ng-template>

<ng-template #tableApplicationList>
  <div class="table-responsive">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Application No</th>
          <th>Service</th>
          <th>Date Submitted</th>
          <th>Last Update</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let application of applicationsList">
          <td>{{ application.applicationNumber }}</td>
          <td>{{ application.serviceName }}</td>
          <td>
            {{
              application.dateCreated
                ? (convertDate(application.dateCreated) | date : 'longDate')
                : '-'
            }}
          </td>
          <td>
            {{
              application.lastUpdated
                ? (convertDate(application.lastUpdated) | date : 'longDate')
                : '-'
            }}
          </td>
          <td>
            <div
              class="application-status irembo-badge"
              [ngClass]="
                getApplicationStatusToColor(application.applicationStatus)
              ">
              <div
                class="pulse"
                [ngClass]="
                  getApplicationStatusPulseToBackgroundColor(
                    application.applicationStatus
                  )
                "></div>
              {{ getApplicationStatusToLabel(application.applicationStatus) }}
            </div>
          </td>
          <td>
            <div class="application-view fw-semibold">
              <a
                type="button"
                (click)="openApplicationDetails(applicationData, application)"
                class="text-decoration-none"
                >{{ 'View' | translate }}</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w-100 mt-3 px-4">
      <irembogov-citizen-pagination
        [minimalPagination]="false"
        [showDirectionText]="true"
        [collectionSize]="totalApplications"
        (paginatedValues)="
          handlePaginate($event)
        "></irembogov-citizen-pagination>
    </div>
  </div>
</ng-template>

<ng-template #updatePassword let-modal>
  <div class="modal-header d-flex align-items-start">
    <div class="d-flex flex-column">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Password' | translate }}
      </h4>
      <span class="form-text">
        {{
          'To change your password, please fill in the fields below.'
            | translate
        }}
      </span>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form
      class="d-flex flex-column gap-3 col-12"
      [formGroup]="updatePasswordForm"
      (ngSubmit)="onSubmit()">
      <div class="col-12">
        <label for="currentPassword">{{
          'Current password' | translate
        }}</label>
        <div class="input-group">
          <input
            [type]="showCurrentPassword ? 'text' : 'password'"
            class="form-control form-control-lg"
            id="currentPassword"
            formControlName="currentPassword"
            [placeholder]="'Enter your current password' | translate" />
          <span class="input-group-text bg-transparent">
            <i
              class="fa-regular text-muted"
              [ngClass]="showCurrentPassword ? 'fa-eye-slash' : 'fa-eye'"
              (click)="togglePasswordVisibility('currentPassword')"></i>
          </span>
        </div>
        <div
          *ngIf="
            updatePasswordForm.get('currentPassword')?.invalid &&
            updatePasswordForm.get('currentPassword')?.touched
          "
          class="text-danger">
          {{ 'Current password is required.' | translate }}
        </div>
      </div>

      <div class="col-12">
        <label for="newPassword">{{ 'New password' | translate }}</label>
        <div class="input-group">
          <input
            [type]="showNewPassword ? 'text' : 'password'"
            class="form-control form-control-lg"
            id="newPassword"
            formControlName="newPassword"
            [placeholder]="'Create password' | translate" />
          <span class="input-group-text bg-transparent">
            <i
              class="fa-regular text-muted"
              [ngClass]="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"
              (click)="togglePasswordVisibility('newPassword')"></i>
          </span>
        </div>
        <div id="passwordHelpBlock" class="form-text">
          {{
            'Your new password must be more than 8 characters, and may contain letters and numbers.'
              | translate
          }}
        </div>
        <div
          *ngIf="
            updatePasswordForm.get('newPassword')?.invalid &&
            updatePasswordForm.get('newPassword')?.touched
          "
          class="text-danger">
          {{ 'New password is required.' | translate }}
        </div>
      </div>

      <div class="col-12">
        <label for="confirmPassword">{{
          'Confirm new password' | translate
        }}</label>
        <div class="input-group">
          <input
            [type]="showConfirmPassword ? 'text' : 'password'"
            class="form-control form-control-lg"
            id="confirmPassword"
            formControlName="confirmPassword"
            [placeholder]="'Re-enter password' | translate" />
          <span class="input-group-text bg-transparent">
            <i
              class="fa-regular text-muted"
              [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
              (click)="togglePasswordVisibility('confirmPassword')"></i>
          </span>
        </div>
        <div
          *ngIf="
            updatePasswordForm.get('confirmPassword')?.invalid &&
            updatePasswordForm.get('confirmPassword')?.touched
          "
          class="text-danger">
          {{ 'Confirming your new password is required.' | translate }}
        </div>
        <div
          *ngIf="updatePasswordForm.errors?.['passwordMismatch']"
          class="text-danger">
          {{ 'Passwords do not match.' | translate }}
        </div>
      </div>

      <div class="modal-footer d-flex flex-column flex-lg-row-reverse gap-lg-2">
        <button
          type="submit"
          class="btn col-12 col-lg-5 flex-lg-fill me-lg-2 btn-primary fw-semibold"
          [disabled]="updatePasswordForm.invalid || changingPassword">
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            *ngIf="changingPassword">
            <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
          </div>
          {{ 'Update password' | translate }}
        </button>
        <button
          type="button"
          class="btn col-12 col-lg-5 flex-lg-fill cancel-btn btn-link text-decoration-none"
          (click)="modal.dismiss('Cancel')">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #applicationData let-offcanvas>
  <div class="offcanvas-header">
    <div class="d-flex flex-column align-items-start">
      <h2 class="title">{{ 'Application details' | translate }}</h2>
      <small>{{
        'View your application details and current status' | translate
      }}</small>
    </div>
    <div class="fs-1 d-flex align-items-start">
      <button class="btn btn-close" (click)="offcanvas.dismiss()"></button>
    </div>
  </div>
  <div class="offcanvas-body" *ngIf="applicationDetails">
    <div class="hstack gap-2 w-100">
      <button
        *ngIf="
          applicationDetails !== undefined &&
          selectedApplication?.priceType !== 'FREE' &&
          applicationDetails?.applicationStatus !== 'PAYMENT_PENDING'
        "
        (click)="getPaymentReceipt(selectedApplication?.applicationNumber)"
        [disabled]="downloadingReceipt"
        [ngClass]="{ disabled: downloadingReceipt }"
        class="btn btn-download d-flex flex-row gap-2 align-items-center">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="downloadingReceipt">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        <i class="icon-file-download-02" *ngIf="!downloadingReceipt"></i>
        {{ 'Download receipt' | translate }}
      </button>
      <button
        *ngIf="
          applicationDetails !== undefined &&
          applicationDetails?.applicationStatus === 'PAYMENT_PENDING'
        "
        (click)="makePayment()"
        class="btn btn-primary d-flex flex-row gap-2 align-items-center text-white">
        <i class="icon-credit-card-01"></i>{{ 'Pay' | translate }}
      </button>
      <button
        *ngIf="
          applicationDetails !== undefined &&
          applicationDetails?.applicationStatus?.includes(
            'PENDING_RESUBMISSION'
          )
        "
        (click)="
          editApplication(
            selectedApplication?.applicationId ?? '',
            applicationDetails.applicationStatus,
            selectedApplication?.applicationNumber ?? '',
            selectedApplication?.serviceId ?? '',
            selectedApplication?.serviceName ?? ''
          )
        "
        class="btn btn-primary d-flex flex-row gap-2 align-items-center text-white">
        <i class="icon-edit-05"></i>{{ 'Edit' | translate }}
      </button>
    </div>

    <div class="app-status" *ngIf="applicationDetails">
      <h3 class="title">Status</h3>
      <div *ngIf="applicationDetails.applicationStateTrackers.length > 0">
        <ol class="timeline">
          <li
            *ngFor="
              let step of applicationDetails?.applicationStateTrackers;
              let index = index
            "
            class="step-item">
            <div class="step-icon-container">
              <div class="step-icon">
                <img src="assets/icons/status-check.svg" alt="" />
              </div>
            </div>
            <ng-template #otherStatus>
              <div class="step-content">
                <div class="title">
                  {{
                    getApplicationStatusToLabel(step.applicationState)
                      | translate
                  }}
                </div>
                <div class="item_text">
                  {{ step.stateTimestamp | date : 'medium' }}
                </div>
              </div>
            </ng-template>
            <ng-container
              *ngIf="
                step.applicationState.includes('PENDING_RESUBMISSION');
                else otherStatus
              ">
              <div class="step-content">
                <div class="title">
                  {{ 'Returned for my action' | translate }}
                </div>
                <div class="item_text">
                  {{
                    'Additional information required. Please review and update your application'
                      | translate
                  }}
                </div>
                <div
                  class="feedback-container"
                  *ngIf="
                    applicationDetails?.applicationFeedbacks
                      ?.feedbackResponseDetails &&
                    applicationDetails.applicationFeedbacks
                      .feedbackResponseDetails.length
                  ">
                  <irembogov-application-feedback
                    [applicationFeedBack]="
                      getActiveFeedback(
                        applicationDetails.applicationFeedbacks
                          .feedbackResponseDetails
                      )
                    "></irembogov-application-feedback>
                </div>
              </div>
            </ng-container>
          </li>
        </ol>
      </div>
    </div>
    <button
      class="toggle-button btn"
      (click)="viewSummaryToggle()"
      type="button">
      <i [ngClass]="!viewSummary ? 'icon-eye' : 'icon-eye-off'"></i>
      {{ viewSummaryText | translate }}
    </button>

    <ng-container *ngIf="applicationDetails && viewSummary">
      <div class="section">
        <irembogov-application-summary
          [showTitle]="false"
          [application]="selectedApplication"
          [applicationSummary]="applicationSummary"
          [applicationBaseUrl]="applicationBaseUrl"
          [applicationPath]="applicationPath"></irembogov-application-summary>
      </div>
    </ng-container>
  </div>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="!applicationDetails"></irembogov-irembo-loading-circle-spinner>
</ng-template>

<ng-template #languageSelector let-modal>
  <div class="modal-header d-flex">
    <div class="d-flex flex-column">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Select certificate language' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div
    class="modal-body"
    *ngIf="
      selectedCertificate &&
      certificateNames[selectedCertificate.applicationNumber]
    ">
    <button
      class="d-flex justify-content-between align-items-center locale w-100"
      (click)="setSelectedLanguage(item['locale'])"
      type="button"
      [class.selected]="item['locale'] === selectedLanguage"
      *ngFor="
        let item of certificateNames[selectedCertificate.applicationNumber];
        index as i
      ">
      <div class="d-flex flex-row gap-2">
        <div class="locale-flag">{{ item['localeFlag'] }}</div>
        <div class="locale-name">{{ item['localeName'] }}</div>
      </div>
      <input
        type="radio"
        name="language"
        [id]="item['localeName']"
        [value]="item['locale']"
        [(ngModel)]="selectedLanguage" />
    </button>
    <div
      class="modal-footer mt-3 d-flex flex-column flex-lg-row-reverse gap-lg-2">
      <button
        *ngIf="languageMode === documentMode.download"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="
          downloadCertificate(selectedCertificate, selectedLanguage || '')
        "
        [disabled]="!selectedLanguage || isDownloadingCertificate">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="isDownloadingCertificate">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        {{ 'Download' | translate }}
      </button>
      <button
        *ngIf="languageMode === documentMode.preview"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="modal.close(selectedLanguage)"
        [disabled]="!selectedLanguage">
        {{ 'Preview' | translate }}
      </button>
      <button
        type="button"
        class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
        (click)="onCancelLanguageSelector()">
        {{ 'Cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #attachment_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="fw-normal preview-header">
        <i class="fa fa-file-text"></i>
        {{ selectedAttachment?.serviceName }} -
        {{ selectedAttachment?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedAttachment?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>
