export const environment = {
  production: true,
  authServiceUrl: 'https://id.irembohub.com',
  authRealm: 'irembo',
  authClientId: 'citizens-portal',
  authSuccessRedirectUrl: 'https://new.irembo.gov.rw',
  apiGatewayBaseUrl: 'https://api.irembohub.com',
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  IREMBOPAY_CDN:
    'https://dashboard.sandbox.irembopay.com/assets/payment/inline.js',
  IREMBOPAY_TEST: true,
  locale: 'IremboPay.locale.EN',
  knowledgeBaseUrl: 'https://support.irembo.gov.rw/en/support/home',
  DEFAULT_LOCALE: 'en-US',
  iremboPrivacyPolicyUrl: '/legal-information',
  SURVEY_SPARROW_WIDGET_TOKEN: 'tt-270a2e',
  SURVEY_SPARROW_ACCOUNT: 'irembo.surveysparrow.com',
  SURVEY_SPARROW_SURVEY_NAME: 'irembogov-website-survey',
  PHONE_NUMBER_REGEX: '^07[2-9][0-9]{7}$',
  translationSourceUrl: 'https://api.irembohub.com',
};
