<div class="main-container irembogov-service-details">
  <div
    *ngIf="!selectedService || isLoading"
    class="d-flex align-items-center justify-content-center text-center top-margin-spinner py-5">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="service-details bg-white" *ngIf="selectedService">
    <div class="nav d-flex flex-column justify-content-between">
      <a
        [routerLink]="['/']"
        class="text-decoration-none text-primary fw-semibold fst-normal lh-lg"
        ><i class="fa-solid fa-angle-left pe-2"></i>
        {{ 'Back to all services' | translate }}</a
      >
      <h2 class="service-name mt-2">
        {{ selectedService.serviceName | translate }}
      </h2>
    </div>
    <div
      class="d-flex flex-column justify-content-center justify-content-lg-start flex-lg-row gap-lg">
      <div class="col-12 col-lg-4 col-md-12">
        <div class="p-4 service-items rounded-4 mb-3">
          <h3 class="mb-4">{{ 'Service details' | translate }}</h3>
          <div class="d-flex flex-row gap-2">
            <div class="col-1">
              <i class="icon-tag-01 text-primary mt-1"></i>
            </div>
            <div class="col-11 d-flex flex-column justify-content-between">
              <div class="item-label">{{ 'Price' | translate }}</div>
              <p class="item">
                {{
                  selectedService.priceType === priceType.FREE
                    ? 'FREE'
                    : selectedService.priceType === priceType.FIXED
                    ? selectedService.price + ' ' + selectedService.currency
                    : 'Depends on the case'
                }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-row gap-2">
            <div class="col-1">
              <i class="icon-clock icon-v-align text-primary"></i>
            </div>
            <div class="col-11 d-flex flex-column justify-content-between">
              <div class="item-label">{{ 'Processing time' | translate }}</div>
              <p class="item">
                {{ selectedService.expectedProcessingTime }}
                {{ 'working day(s)' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-row gap-2">
            <div class="col-1">
              <i class="icon-building-07 text-primary"></i>
            </div>
            <div class="col-11 d-flex flex-column justify-content-between">
              <div class="item-label">{{ 'Service Provider' | translate }}</div>
              <p class="item">
                {{ selectedService.organization.name | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="service-summary col-12 col-lg-6 col-md-12"
        [innerHTML]="selectedService.serviceSummary | translate"></div>
    </div>
    <div
      class="apply-section rounded-4 mt-4 mb-3 mb-lg-0 mb-md-5 d-flex flex-column flex-lg-row gap-0 gap-md-3 justify-content-center align-items-center align-content-center justify-content-lg-between justify-content-md-between">
      <div class="apply-text text-center">
        {{ 'Ready to apply?' | translate }}
      </div>
      <button
        class="btn apply-button btn-primary text-white col-12 col-lg-auto col-md-12"
        (click)="onApplyServiceClicked()">
        {{ 'Apply now' | translate
        }}<i class="fa-solid fa-angle-right px-2"></i>
      </button>
    </div>
  </div>
</div>
<ng-template #noBusinessModal let-modal>
  <div class="modal-header d-flex align-items-start">
    <div class="d-flex flex-column">
      <h4 class="modal-title mt-2" id="modal-basic-title">
        {{ 'Business Required' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="col-12 mb-4">
      <p class="">
        {{
          "To apply for this service, you'll need to link a business to your profile first"
            | translate
        }}.
        {{ ' ' }}
        {{ "It's quick and easy" | translate }}!
      </p>
    </div>
  </div>

  <div
    class="modal-footer d-flex flex-column flex-sm-column flex-md-row-reverse gap-md-2 flex-lg-row-reverse gap-lg-2 border-0 align-items-sm-stretch">
    <irembogov-add-business buttonStyling="modal"></irembogov-add-business>
    <button
      type="button"
      [disabled]="navigatingToApplicationPage"
      class="btn cancel-btn btn-lg btn-outline-dark text-decoration-none"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #selectApplicantForBothModal let-modal>
  <div class="modal-header d-flex align-items-start border-0">
    <div class="d-flex flex-column">
      <h4 class="modal-title mt-2" id="modal-select-applicant-for-both-title">
        {{ 'Apply as' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body pt-0">
    <div class="w-100 applicant-for-both-options">
      <button
        *ngFor="let option of bothApplicationtTypeOptions"
        type="button"
        (click)="applicationApplicantTypeFormControl.setValue(option.key)"
        class="btn applicant-option-button {{
          applicationApplicantTypeFormControl.value === option.key
            ? 'selected'
            : ''
        }} w-100 my-1">
        {{ option.label | translate }}
        <small class="w-100 d-block">
          {{ option.description | translate }}
        </small>
      </button>
    </div>
  </div>

  <div
    class="modal-footer d-flex flex-column flex-sm-column flex-md-row gap-md-2 flex-lg-row gap-lg-2 border-0 align-items-sm-stretch">
    <button
      type="button"
      [disabled]="navigatingToApplicationPage"
      class="btn btn-lg cancel-btn btn-outline-dark text-decoration-none"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="
        navigatingToApplicationPage ||
        !applicationApplicantTypeFormControl.value
      "
      class="btn btn-lg btn-primary text-decoration-none"
      (click)="handleSelectedApplicantForBoth()">
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #selectApplicantForBusinessModal let-modal>
  <div class="modal-header d-flex align-items-start border-0">
    <div class="d-flex flex-column">
      <h4
        class="modal-title mt-2"
        id="modal-select-applicant-for-business-title">
        {{ 'Apply as' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body pt-0">
    <div class="w-100 applicant-for-business-options">
      <button
        *ngFor="let business of userBusinesses"
        type="button"
        (click)="setSelectedBusiness(business.guid, business.name)"
        class="btn applicant-option-button {{
          applicationBusinessIdFormControl.value === business.guid
            ? 'selected'
            : ''
        }} w-100 my-1">
        {{ business.name }}
      </button>
    </div>
  </div>

  <div
    class="modal-footer d-flex flex-column flex-sm-column flex-md-row gap-md-2 flex-lg-row gap-lg-2 border-0 align-items-sm-stretch">
    <button
      type="button"
      [disabled]="navigatingToApplicationPage"
      class="btn btn-lg cancel-btn btn-outline-dark text-decoration-none"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      [disabled]="
        navigatingToApplicationPage ||
        !(
          applicationBusinessIdFormControl.value &&
          applicationBusinessNameFormControl.value
        )
      "
      type="button"
      class="btn btn-lg btn-primary text-decoration-none"
      (click)="handleSelectedApplicantForBusiness()">
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>
