<div class="row">
  <ng-container
    *ngFor="let group of data?.formGroup; trackBy: trackByFn; let last = last">
    <div
      [class]="
        last && isOdd(data?.formGroup?.length)
          ? 'col'
          : columnSizeClass(data?.formGroup?.length)
      "
      *ngIf="isNotEmpty(group)">
      <irembogov-non-logeddin-application-form-field-card
        [group]="group"
        [applicationNumber]="applicationNumber"
        [baseUrl]="applicationBaseUrl"
        [applicationPath]="
          applicationPath
        "></irembogov-non-logeddin-application-form-field-card>
    </div>
  </ng-container>
</div>
