<div class="business-list d-flex flex-column flex-lg-row flex-wrap gap-3">
  <div class="business-card" *ngFor="let business of businesses">
    <div class="business-list-item d-flex flex-column gap-2 align-items-start">
      <div class="business-list-item-title">
        {{ business.name }}
        <div class="business-list-item-tin">
          {{ business.tinNumber }}
        </div>
      </div>

      <a class="btn btn-link text-decoration-none text-start my-3">
        {{ 'View details' | translate }}
      </a>

      <div class="business-list-item-user-role">
        {{ business.permissionGroups?.[0] | businessPermissionGroupCodeToLabel | translate }}
      </div>
    </div>
    <div class="divider"></div>
    <div
      class="business-list-item-buttons flex-column flex-xl-row align-items-stretch d-flex justify-content-between gap-2">
      <div class="business-list-left-btns gap-2 d-flex">
        <button
          class="btn btn-link text-decoration-none col-6"
          (click)="viewApplications(business.guid)">
          {{ 'View applications' | translate }}
        </button>
        <button
          class="btn btn-link text-decoration-none col-6"
          (click)="viewCertificates(business.guid)">
          {{ 'View certificates' | translate }}
        </button>
      </div>
      <div class="business-list-right-btns gap-2 d-flex flex-row-xl-reverse">
        <button
          *ngIf="
            business.availablePermissions?.includes(
              'APPROVE_BUSINESS_USER_REQUEST'
            ) ||
            business.availablePermissions?.includes(
              'REJECT_BUSINESS_USER_REQUEST'
            ) ||
            business.availablePermissions?.includes('ADD_BUSINESS_USER') ||
            business.availablePermissions?.includes('REMOVE_BUSINESS_USER')
          "
          class="btn btn-link btn-link-blue text-decoration-none col-12"
          (click)="goToManageTeams(business.guid)">
          {{ 'Manage Teams' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
