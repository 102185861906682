import { APP_INITIALIZER, NgModule, Injector } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppComponent } from './app.component';
import { NotfoundComponent } from './component/notfound/notfound.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  UiModule,
  formlyConfigs,
  CustomFieldStepperComponent,
  ApplicationFormSectionComponent,
  HideFieldWrapperComponent,
  ClientDetailsInterceptor,
  SatHeaderInterceptor,
  initializeKeycloak,
  ReadonlyWrapperComponent,
  HintFieldWrapperComponent,
} from '@irembo-andela/irembogov3-common';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { FindComponent } from './component/find/find.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { LayoutModule } from '@angular/cdk/layout';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ServiceHeaderComponent } from './component/service-header/service-header.component';
import { PreviewApplicationComponent } from './component/preview-application/preview-application.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { CitizenStepperComponent } from './component/irembogov-stepper/irembogov-stepper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ServiceModalComponent } from './component/service-modal/service-modal.component';
import { LoginModalComponent } from './component/login-modal/login-modal.component';
import { ServiceSearchPageComponent } from './component/service-search-page/service-search-page.component';
import { SearchboxComponent } from './component/searchbox/searchbox.component';
import { ServiceSearchResultComponent } from './component/service-search-result/service-search-result.component';
import { SearchByTypeComponent } from './component/search-by-type/search-by-type.component';
import { CitizenProfileComponent } from './component/citizen-profile/citizen-profile.component';
import { InputErrorComponent } from './component/input-error/input-error.component';
import { ServiceDetailsComponent } from './component/service-details/service-details.component';
import { ApplicationPaymentComponent } from './component/application-payment/application-payment.component';
import { NotificationPreferencesComponent } from './component/notification-preferences/notification-preferences.component';
import { AdvanceSearchComponent } from './component/advance-search/advance-search.component';
import { ServiceNotFoundComponent } from './component/service-not-found/service-not-found.component';
import { AddHeaderInterceptor } from './interceptors/add-header-interceptor.service';
import { CitizenPaginationComponent } from './component/citizen-pagination/citizen-pagination.component';
import { VerifyIdentityComponent } from './component/verify-identity/verify-identity.component';
import { FilterApplicationsPipe } from './pipes/filter-applications/filter-applications.pipe';
import { ApplicationFeedbackComponent } from './component/application-feedback/application-feedback.component';
import { FindOffcanvasComponent } from './component/find-offcanvas/find-offcanvas.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ServicePricePipe } from './pipes/service-price/service-price.pipe';
import { GenericContainerComponent } from './component/generic-container/generic-container.component';
import { SharedModule } from './modules/shared/shared.module';
import { FeaturedServicesComponent } from './component/featured-services/featured-services.component';
import { CategoryServicesComponent } from './component/category-services/category-services.component';
import { ServiceViewComponent } from './component/category-services/components/service-view/service-view.component';
import { TrackApplicationComponent } from './component/track-application/track-application.component';
import { VerifyIdentityBannerComponent } from './component/verify-identity-banner/verify-identity-banner.component';
import { VerifyPhoneNumberComponent } from './component/verify-phone-number/verify-phone-number.component';
import { AuthModule } from './modules/auth/auth.module';
import { VerifyCertificateComponent } from './component/verify-certificate/verify-certificate.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyCertificateQrScanComponent } from './component/verify-certificate-qr-scan/verify-certificate-qr-scan.component';
import { PreviewCertificateComponent } from './component/preview-certificate/preview-certificate.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ProfileSettingsComponent } from './component/profile/components/profile-settings/profile-settings.component';
import { IremboMissingTranslationHandler } from '@irembo-andela/irembogov3-common';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
  USE_DEFAULT_LANG,
} from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, finalize, of } from 'rxjs';
import { LocaleService } from './services/locale.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApplicantTypeToggleComponent } from './component/applicant-type-toggle/applicant-type-toggle.component';
import { InlineToggleComponent } from './component/profile/components/inline-toggle/inline-toggle.component';
import { ApplicationListComponent } from './component/profile/components/application-list/application-list.component';
import { DocumentsListComponent } from './component/profile/components/documents-list/documents-list.component';
import { BusinessListComponent } from './component/profile/components/business-list/business-list.component';
import { BusinessApplicationsComponent } from './component/profile/components/business-applications/business-applications.component';
import { PersonalApplicationsComponent } from './component/profile/components/personal-applications/personal-applications.component';
import { AddBusinessComponent } from './component/add-business/add-business.component';
import { AddBusinessSuccessComponent } from './component/add-business-success/add-business-success.component';
import { PersonalDocumentsComponent } from './component/profile/components/personal-documents/personal-documents.component';
import { BusinessCertificatesComponent } from './component/profile/components/business-certificates/business-certificates.component';
import { ManageBusinessTeamsComponent } from './component/profile/components/business-teams/manage-business-teams.component';
import { ListBusinessUsersComponent } from './component/profile/components/business-teams/list-business-users/list-business-users.component';
import { ListBusinessUserRequestsComponent } from './component/profile/components/business-teams/list-business-user-requests/list-business-user-requests.component';
import {
  BusinessPermissionCodeToLabelPipe,
  BusinessPermissionGroupCodeToLabelPipe,
} from './pipes/businesses/business-permission-group-code-to-label.pipe';
import { UsernameInitialsPipe } from './pipes/generic/user-name-initials.pipe';
import { UsernameAndEmailCircleIconComponent } from './component/username-and-email-circle-icon/username-and-email-circle-icon.component';

export function HttpLoaderFactory(
  http: HttpClient,
  localeService: LocaleService
) {
  return new CustomLoader(http, localeService);
}

export class CustomLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    private localeService: LocaleService
  ) {}
  getTranslation(lang: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const apiAddress = `${environment.translationSourceUrl}/admin/v1/translations/static-text/by-locale/${lang}`;
    if (!this.localeService.translationsLoadedBefore) {
      this.localeService.loadingTranslations.next(true);
    }
    return this.httpClient.get(apiAddress, { headers: header }).pipe(
      finalize(() => {
        this.localeService.loadingTranslations.next(false);
        this.localeService.translationsLoadedBefore = true;
      }),
      catchError(error => {
        // Return an empty object to trigger missing translation handler
        return of({});
      })
    );
  }
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () => {
    const defaultLanguage = injector.get('DEFAULT_LANGUAGE'); // Get default language from the injector

    translate.addLangs([defaultLanguage]);
    translate.setDefaultLang(defaultLanguage);
    translate.setTranslation(defaultLanguage, {});
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    FindComponent,
    ServiceHeaderComponent,
    PreviewApplicationComponent,
    NavbarComponent,
    CitizenStepperComponent,
    ServiceModalComponent,
    LoginModalComponent,
    ServiceSearchPageComponent,
    SearchboxComponent,
    ServiceSearchResultComponent,
    SearchByTypeComponent,
    CitizenProfileComponent,
    InputErrorComponent,
    ServiceDetailsComponent,
    ApplicationPaymentComponent,
    NotificationPreferencesComponent,
    AdvanceSearchComponent,
    ServiceNotFoundComponent,
    VerifyIdentityComponent,
    CitizenPaginationComponent,
    UsernameAndEmailCircleIconComponent,
    FilterApplicationsPipe,
    UsernameInitialsPipe,
    BusinessPermissionGroupCodeToLabelPipe,
    BusinessPermissionCodeToLabelPipe,
    ApplicationFeedbackComponent,
    FindOffcanvasComponent,
    ServicePricePipe,
    GenericContainerComponent,
    FeaturedServicesComponent,
    CategoryServicesComponent,
    ServiceViewComponent,
    TrackApplicationComponent,
    VerifyIdentityBannerComponent,
    VerifyPhoneNumberComponent,
    VerifyCertificateComponent,
    VerifyCertificateQrScanComponent,
    PreviewCertificateComponent,
    ProfileComponent,
    ProfileSettingsComponent,
    ApplicantTypeToggleComponent,
    InlineToggleComponent,
    ApplicationListComponent,
    DocumentsListComponent,
    BusinessListComponent,
    BusinessApplicationsComponent,
    ManageBusinessTeamsComponent,
    ListBusinessUsersComponent,
    ListBusinessUserRequestsComponent,
    PersonalApplicationsComponent,
    AddBusinessComponent,
    AddBusinessSuccessComponent,
    PersonalDocumentsComponent,
    BusinessCertificatesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    HttpClientModule,
    KeycloakAngularModule,
    CdkStepperModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      ...formlyConfigs(),
      wrappers: [
        {
          name: 'hidden-field-wrapper',
          component: HideFieldWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'radio',
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
        {
          name: 'hint-field-wrapper',
          component: HintFieldWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'radio',
            'sections',
            'block',
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
        {
          name: 'irembogov-readonly-wrapper',
          component: ReadonlyWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'radio',
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
      ],
      ...{
        types: [
          ...formlyConfigs().types.map(type => ({
            component: type.component,
            name: type.name,
            wrappers: ['form-field'],
          })),
          { name: 'sections', component: CustomFieldStepperComponent },
          { name: 'block', component: ApplicationFormSectionComponent },
        ],
      },
    }),
    FormlyBootstrapModule,
    UiModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
    NgIf,
    LayoutModule,
    PdfViewerModule,
    SharedModule,
    AuthModule,
    NgOtpInputModule,
    NgSelectModule,
    TranslateModule.forRoot({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, LocaleService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IremboMissingTranslationHandler,
      },
    }),
  ],
  providers: [
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => {
        return initializeKeycloak(keycloakService, {
          url: environment.authServiceUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId,
          redirectUri: environment.authSuccessRedirectUrl,
        });
      },
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientDetailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SatHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: 'DEFAULT_LANGUAGE',
      useValue: localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
    },
    {
      provide: USE_DEFAULT_LANG,
      useValue: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
