import { IOrganisationsList } from './../models/organisations-list.model';
import { IAttachmentsList } from './../models/attachments-list.model';
import { IServicesList } from './../models/services-list.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apis } from '../app-apis';
import { convertObjectToQueryString } from '../helpers/query-param-converter';
import { ICategoriesList } from '../models/categories-list.model';
import { IService } from '../models/service.model';
import { IFeatureServiceList } from '../models/featured-services-list.model';
import { IResponse } from '../models/response.model';
import { map, Observable } from 'rxjs';
import { IServiceData, IServiceNextStep } from '../models/layout.model';
import { IApplicationRequest } from '../models/application-request.model';
import { IApplicationResponse } from '../models/application-response.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IApplicationDraftRequest } from '../models/application-draft-request.model';
import { IApplicationDraft } from '../models/application-draft.model';
import { IServiceTranslation } from '../models/service/service-translation.model';
import { ICategoryServices } from '../models/service/category-services-response.model';

@Injectable({
  providedIn: 'root',
})
export class PublicServicesService {
  private adminBaseUrl = environment.apiGatewayBaseUrl + '/admin';
  private serviceAPIs = apis.service;
  private changeRequestAPIs = apis.changeRequest;
  private applicationAPIs = apis.application;
  private serviceBaseUrl = environment.apiGatewayBaseUrl + '/service';
  private applicationBaseUrl = environment.apiGatewayBaseUrl + '/application';

  constructor(private http: HttpClient) {}

  getServices(queryParams: {
    page: number;
    size: number;
    query?: string;
    categoryId?: string;
    organizationId?: string;
  }) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IServicesList>(
      `${this.serviceBaseUrl}${this.serviceAPIs.services}?${queryParamsStr}`
    );
  }

  getFeaturedServices() {
    return this.http.get<IFeatureServiceList>(
      `${this.serviceBaseUrl}${this.serviceAPIs.featured}`
    );
  }

  getCategories() {
    return this.http.get<ICategoriesList>(
      `${this.adminBaseUrl}${this.serviceAPIs.categories}`
    );
  }

  getOrganisations(queryParams: { page: number; size: number }) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IOrganisationsList>(
      `${this.adminBaseUrl}${this.serviceAPIs.organisations}?${queryParamsStr}`
    );
  }

  getServiceById(serviceId: string): Observable<IService> {
    return this.http
      .get<IResponse<IService>>(
        `${this.serviceBaseUrl}${this.serviceAPIs.public}/${serviceId}`
      )
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  getChangeRequestApplicationForm(
    changeRequestId: string
  ): Observable<FormlyFieldConfig[]> {
    return this.http
      .get<IResponse<IServiceData>>(
        `${this.serviceBaseUrl}${this.changeRequestAPIs.details}/${changeRequestId}/form-definition`
      )
      .pipe(
        map(res => {
          return res.data.fields;
        })
      );
  }

  getServiceNextSteps(serviceId: string): Observable<IServiceNextStep[]> {
    return this.http
      .get<IResponse<IServiceNextStep[]>>(
        `${this.serviceBaseUrl}${this.serviceAPIs.public}/${serviceId}/next-steps`
      )
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  getServiceTranslationsByLocale(serviceId: string, locale: string) {
    return this.http.get<IResponse<IServiceTranslation>>(
      `${this.serviceBaseUrl}${this.serviceAPIs.public}/${serviceId}/translations/${locale}`
    );
  }

  createApplication(
    application: IApplicationRequest,
    trafficClass: string | undefined
  ): Observable<IResponse<IApplicationResponse>> {
    if (trafficClass) {
      return this.http.post<IResponse<IApplicationResponse>>(
        `${this.applicationBaseUrl}/v1/${trafficClass}/submit-application`,
        application
      );
    } else {
      return this.http.post<IResponse<IApplicationResponse>>(
        `${this.applicationBaseUrl}/v1/submit-application`,
        application
      );
    }
  }

  resubmitApplication(
    application: IApplicationRequest,
    trafficClass: string | undefined
  ): Observable<IResponse<IApplicationResponse>> {
    if (trafficClass) {
      return this.http.post<IResponse<IApplicationResponse>>(
        `${this.applicationBaseUrl}/v1/${trafficClass}/resubmit-application`,
        application
      );
    } else {
      return this.http.post<IResponse<IApplicationResponse>>(
        `${this.applicationBaseUrl}/v1/resubmit-application`,
        application
      );
    }
  }

  saveApplicationDraft(
    applicationDraft: IApplicationDraftRequest
  ): Observable<IResponse<IApplicationDraft>> {
    return this.http.post<IResponse<IApplicationDraft>>(
      `${this.applicationBaseUrl}${this.applicationAPIs.saveDraft}`,
      applicationDraft
    );
  }

  getApplicationDraft(serviceId: string): Observable<any> {
    return this.http
      .get<IResponse<any>>(
        `${this.applicationBaseUrl}${this.applicationAPIs.getDraft}`,
        {
          headers: { serviceId: serviceId },
        }
      )
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  getCategoryServices(
    applicantType: string[]
  ): Observable<IResponse<ICategoryServices[]>> {
    let params = new HttpParams();

    applicantType.forEach(type => {
      params = params.append('applicantType', type);
    });

    return this.http.get<IResponse<ICategoryServices[]>>(
      `${this.serviceBaseUrl}${this.serviceAPIs.serviceCategories}`,
      { params }
    );
  }
}
