<form *ngIf="form" [formGroup]="form" [ngClass]="customClass">
  <ng-container *ngFor="let field of configs; let index = index">
    <div
      class="row mb-3"
      *ngIf="!field.parent || form.controls[field.parent].value">
      <div class="col">
        <label [for]="field.key" class="form-label tt_norms400"
          >{{ field.label | translate }} <span>*</span>
        </label>

        <ng-container *ngIf="field.type === 'customdropdown'">
          <irembogov-select
            [loading]="loading[field.key]"
            [appendTo]="appendTo"
            [formControlName]="field.key"
            [items]="items && items[field.key] ? items[field.key] : null"
            [bindLabel]="field.bindlabel ? field.bindlabel : ''"
            ngDefaultControl
            [bindValue]="field.bindvalue ? field.bindvalue : ''"
            [placeholder]="field.placeholder"
            [labelForId]="field.key"
            [multiple]="false"
            [virtualScroll]="true"
            (itemSelected)="
              onSelectChange($event, field.key, index)
            "></irembogov-select>
        </ng-container>

        <ng-container *ngIf="field.type === 'number'">
          <input
            type="number"
            [formControlName]="field.key"
            (keyup)="onKeyUp($event)"
            class="form-control"
            placeholder="{{ field.placeholder }}" />
          <div class="form-text">
            {{ 'Total available seats: ' | translate }} {{ remainingSeats }}
          </div>
        </ng-container>

        <div
          class="invalid-feedback d-block"
          *ngIf="
            form.controls[field.key].invalid &&
            (form.controls[field.key].dirty ||
              form.controls[field.key].touched) &&
            form.controls[field.key].errors
          ">
          <span *ngIf="form.controls[field.key].errors?.['required']">
            {{ field.requiredErrorMessage | translate }}
          </span>
          <span
            *ngIf="form.controls[field.key].errors?.['max'] && field.maxSeatErrorMessage">
            {{ field.maxSeatErrorMessage | translate }}
          </span>
          <span
            *ngIf="form.controls[field.key].errors?.['min'] && field.minSeatErrorMessage">
            {{ field.minSeatErrorMessage | translate }}
          </span>
          <span
            *ngIf="form.controls[field.key].errors?.['pattern'] && field.numbersOnlyErrorMessage">
            {{ field.numbersOnlyErrorMessage | translate }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</form>
