import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ISlotBookingConfig,
  ISlotValidity,
} from '../../models/slot-booking.model';

@Component({
  selector: 'irembogov-slot-booking',
  templateUrl: './irembogov-slot-booking.component.html',
  styleUrls: ['./irembogov-slot-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrembogovSlotBookingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IrembogovSlotBookingComponent,
      multi: true,
    },
  ],
})
export class IrembogovSlotBookingComponent
  implements OnInit, Validator, ControlValueAccessor, OnChanges
{
  @Input() configs: ISlotBookingConfig[] = [];
  @Input() customClass: string | undefined;
  @Input() loading: Record<string, boolean> = {};
  @Input() items: Record<string, Record<string, unknown>[] | null> | undefined;
  form: FormGroup | undefined;
  @Output() activeDropDown = new EventEmitter<ISlotBookingConfig>();
  @Input() isTouched = false;
  @Input() remainingSeats = 0;
  @Input() slotValidity: ISlotValidity | undefined;
  @Output() numberOfSeatsChange = new EventEmitter<any>();
  @Output() fetchMore = new EventEmitter<any>();
  @Output() rfaEvent = new EventEmitter<Record<string, unknown>>();
  @Input() appendTo = 'body';

  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group(this.buildForm());
    this.activeDropDown.emit({
      ...this.configs[0],
      value: undefined,
      index: 0,
    });
  }

  resetDropDowns(_: string, index: number) {
    this.configs.slice(index).forEach(child => {
      this.form?.controls[child.key].setValue(undefined);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['isTouched'] && this.form?.invalid) {
      this.form.markAllAsTouched();
    }

    if (changes?.['remainingSeats']) {
      const seatKey = this.configs[3].key;
      const seatControl = this.form?.controls[seatKey];
      if (seatControl) {
        seatControl.setValidators([
          Validators.required,
          Validators.max(this.remainingSeats),
          Validators.min(1),
          Validators.pattern(/^[0-9]+$/),
        ]);
        seatControl.updateValueAndValidity();
      }
      this.cd.detectChanges();
    }

    if (changes?.['slotValidity']) {
      this._onValidationChange();
    }
  }

  onSelectChange(value: Record<string, unknown>, key: string, index: number) {
    const nextItemIndex = index + 1;
    this.resetDropDowns(key, nextItemIndex);
    this._onChange(this.form?.value);
    this._onTouch(this.form?.value);
    if (this.configs.length - 1 === index || !value) return;
    this.activeDropDown.emit({
      ...this.configs[nextItemIndex],
      value,
      index: nextItemIndex,
    });
  }

  onKeyUp(event: any) {
    this._onChange(this.form?.value);
    this._onTouch(this.form?.value);
    this.numberOfSeatsChange.emit(event);
    this.cd.detectChanges();
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  writeValue(obj: { [key: string]: any }): void {
    if (obj) {
      this.form?.setValue(obj);
      this.rfaEvent.emit(obj);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  validate(): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    const validFrom = this.slotValidity?.validFrom;
    const validTo = this.slotValidity?.validTo;

    if (validFrom && validTo) {
      const start = new Date(validFrom);
      const end = new Date(validTo);
      const now = new Date();

      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);

      if (!(start <= now && now <= end)) {
        validationErrors['invalidSlot'] = true;
      }
    }

    if (this.form?.invalid) {
      validationErrors['invalid'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  buildForm(): Record<string, FormControl> {
    const group: Record<string, FormControl> = {};
    this.configs.forEach(field => {
      if (field.type === 'number') {
        group[field.key] = new FormControl(null, [
          Validators.required,
          Validators.max(this.remainingSeats),
          Validators.min(1),
          Validators.pattern(/^[0-9]+$/),
        ]);
      } else {
        group[field.key] = new FormControl(null, Validators.required);
      }
    });

    return group;
  }

  handlePagination(_event: any, index: number) {
    this.fetchMore.emit({ index });
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }
}
