<div class="d-flex flex-column h-100 bg-white">
  <section class="search-sec bg-blue-gradient">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center mb-4">
          <h1 class="hero-find-service">
            {{ 'Access all public services' | translate }}
          </h1>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-6">
          <irembogov-searchbox
            size="lg"
            class="find-home"
            (resultSelected)="handleOnSelect($event)"></irembogov-searchbox>
        </div>
      </div>
    </div>
  </section>
  <section
    class="toggle-buttons d-flex justify-content-center align-items-center h-auto d-none d-lg-flex d-md-flex">
    <irembogov-applicant-type-toggle
      (viewChange)="
        handleApplicantTypeChange($event)
      "></irembogov-applicant-type-toggle>
  </section>
  <section
    class="featured-sec bg-white"
    *ngIf="featuredServicesList.length > 0">
    <irembogov-featured-services
      [services]="featuredServicesList"
      (selectedService)="handleOnSelect($event)"></irembogov-featured-services>
  </section>
  <section
    class="mobile-applicant-toggle d-lg-none d-md-none"
    [ngClass]="{ 'mt-4': featuredServicesList.length === 0 }">
    <irembogov-applicant-type-toggle
      (viewChange)="
        handleApplicantTypeChange($event)
      "></irembogov-applicant-type-toggle>
  </section>
  <section class="category-services">
    <irembogov-category-services
      [selectedApplicantType]="selectedApplicantType"
      (showServiceDetails)="
        handleOnSelect($event)
      "></irembogov-category-services>
  </section>
  <section class="contact">
    <div
      class="footer-contact d-flex flex-column flex-lg-row flex-md-row justify-content-between align-items-start align-items-lg-center align-items-md-center gap-3">
      <div
        class="col col-lg-4 col-md-4 col-12 d-flex flex-column justify-content-start p-3 ps-lg-0 ps-md-0">
        <p class="deep-blue contact">{{ 'Contact us' | translate }}</p>
        <h3>{{ 'Do you need help using IremboGov' | translate }}</h3>
        <p class="chat">{{ 'Chat with our friendly team.' | translate }}</p>
      </div>
      <div
        class="col col-lg-4 col-md-4 col-12 d-flex flex-column justify-content-start bg-light rounded-3 p-3">
        <i class="fa-solid fa-phone mx-0 mb-3 deep-blue"></i>
        <p class="dark-text mx-0">{{ 'Call our customer care' | translate }}</p>
        <a href="#" class="text-decoration-none mx-0 deep-blue">9099</a>
      </div>
      <div
        class="col col-lg-4 col-md-4 col-12 d-flex flex-column justify-content-start bg-light rounded-3 ps-3 py-3">
        <i class="fa-regular fa-comments mx-0 mb-3 deep-blue"></i>
        <p class="mx-0 dark-text">{{ 'Chat with support' | translate }}</p>
        <a href="#" class="text-decoration-none mx-0 deep-blue"
          >support@irembo.com</a
        >
      </div>
    </div>
  </section>
  <irembogov-service-modal
    (modalID)="modalID = $event"
    [data]="selectedApplication"></irembogov-service-modal>
</div>
